<template>
  <div>
    {{ mappedData.name }}
    <v-tooltip
      v-if="mappedData.mappedFrom"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          color="green"
          small
          v-bind="attrs"
          v-on="on"
        >
          mdi-swap-horizontal
        </v-icon>
      </template>
      <span>Mappé à partir de {{ mappedData.mappedFrom }}</span>
    </v-tooltip>
    <v-tooltip
      v-else-if="mappedData.isRequired"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          color="orange"
          v-bind="attrs"
          v-on="on"
        >
          mdi-swap-horizontal
        </v-icon>
      </template>
      <span>Mapping absent, la donnée sera ignorée</span>
    </v-tooltip>

    <v-tooltip
      v-if="mappedData.isNew"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          small
          v-bind="attrs"
          v-on="on"
        >
          mdi-information-variant-circle-outline
        </v-icon>
      </template>
      <span>Une propriété sera créée en se basant sur son nom</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  props: {
    mappedData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
